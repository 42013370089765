<template>
    <div id="modal-pre-line"></div>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> CSV一括登録</h1>
        <router-link :to="{name: 'Project'}" class="btn btn-outline-primary">
        <i class="bi bi-reply"></i> 案件一覧へ
        </router-link>
    </header>

    <section class="section">
        <section class="section">
            <header class="section-title">
                <h2>CSV定義</h2>
            </header>
            <table class="table table-bordered table-hover">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center">項目名</th>
                        <th class="text-center">制約等</th>
                        <th class="text-center">記入例</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="align-middle table-light">事業部</td>
                        <td class="align-middle"><strong class="text-danger">必須</strong> / 選択肢に一致</td>
                        <td class="align-middle">認証パートナー</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">チャネル</td>
                        <td class="align-middle">選択肢に一致</td>
                        <td class="align-middle">FAX DM</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">反響日</td>
                        <td class="align-middle">未記入の場合は今日</td>
                        <td class="align-middle">2023/04/01</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">会社名</td>
                        <td class="align-middle"><strong class="text-danger">必須</strong> / 100文字以内</td>
                        <td class="align-middle">株式会社AD5</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">氏名</td>
                        <td class="align-middle"><strong class="text-danger">必須</strong> / 100文字以内</td>
                        <td class="align-middle">鈴木 北斗</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">フリガナ</td>
                        <td class="align-middle">100文字以内</td>
                        <td class="align-middle">すずき ほくと</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">部署</td>
                        <td class="align-middle">100文字以内</td>
                        <td class="align-middle">開発部</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">役職</td>
                        <td class="align-middle">100文字以内</td>
                        <td class="align-middle">主任</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">電話番号</td>
                        <td class="align-middle">100文字以内</td>
                        <td class="align-middle">080-0000-0000</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">メールアドレス</td>
                        <td class="align-middle">100文字以内</td>
                        <td class="align-middle">suzuki@ad5.jp</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">郵便番号</td>
                        <td class="align-middle">100文字以内</td>
                        <td class="align-middle">531-0072</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">都道府県</td>
                        <td class="align-middle">選択肢に一致</td>
                        <td class="align-middle">大阪府</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">市区郡</td>
                        <td class="align-middle">100文字以内</td>
                        <td class="align-middle">大阪市北区</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">町名・番地</td>
                        <td class="align-middle">100文字以内</td>
                        <td class="align-middle">豊崎3-15-5</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">代表者名</td>
                        <td class="align-middle">100文字以内</td>
                        <td class="align-middle">片岡 高志</td>
                    </tr>
                    <tr>
                        <td class="align-middle table-light">業種</td>
                        <td class="align-middle">選択肢に一致</td>
                        <td class="align-middle">情報通信業</td>
                    </tr>
                </tbody>
            </table>
            <button class="btn btn-outline-primary" @click="exportTemplateCsv()"><i class="bi bi-download"></i> テンプレートCSV</button>
        </section>

        <section class="section">
            <header class="section-title">
                <h2>一括登録</h2>
            </header>

            <div class="mb-3">
                <div class="form-group col-md-8 mb-3">
                <label>CSVファイル</label>
                <input
                    type="file"
                    class="form-control"
                    accept="text/csv"
                    required
                    ref="file"
                    @change="uploadCsv"
                >
                </div>
            </div>
            <p class="mb-3">以下の項目は、CSV内の全案件に一括反映されます。</p>
            <div class="row mb-3 align-items-end">
                <div class="col-6">
                    <label class="form-label">ステータス <i class="required"></i></label>
                    <form-select
                        v-model="status"
                        :options="Status.options()"
                        :required="true"
                    ></form-select>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <label class="form-label">反響備考</label>
                    <form-textarea
                        v-model="contact_note"
                    ></form-textarea>
                </div>
            </div>
        </section>

        <section class="section">
            <button @click="importCsv()" class="btn btn-lg btn-info">登録</button>
        </section>
    </section>
</template>

<script>
import Status from '@/models/enums/status';
import FormSelect from '@/components/forms/FormSelect';
import FormTextarea from '@/components/forms/FormTextarea';
import { downloadCsv } from '@/utilities/download';

export default {
    name: 'ProjectImport',
    components: {
        FormSelect,
        FormTextarea,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // enum options
            Status,

            file: null,
            status: Status.NOT_CONTACT,
            contact_note: null,
        }
    },
    mounted() {
    },
    methods: {
        uploadCsv(e) {
            this.file = e.target.files[0];
        },
        // CSV一括登録
        importCsv() {
            this.startScreenLoading();

            let header = {headers: {'content-type': 'multipart/form-data'}}; //fileを送るheader
            let form_data = new FormData();
            form_data.append("file", this.file);
            form_data.append('status', this.status);
            form_data.append('contact_note', this.contact_note);

            this.$http.post('/project/import/csv', form_data, header)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.file = null
                this.$refs.file.value = null;
                this.endScreenLoading();
            });
        },
        exportTemplateCsv() {
            let csv = [
                '事業部',
                'チャネル',
                '反響日',
                '会社名',
                '氏名',
                'フリガナ',
                '部署',
                '役職',
                '電話番号',
                'メールアドレス',
                '郵便番号',
                '都道府県',
                '市区郡',
                '町名・番地',
                '代表者名',
                '業種',
            ].map((label) => '"' + label + '"').join(',');
            let filename = "template.csv";
            downloadCsv(csv, filename);
        }
    }
}
</script>

<style scoped>
</style>

<style>
/* TODO モーダルのマスタッシュ構文でエラーメッセージを改行できないためグローバルで適用している */
.modal-multiline-pre-line {
    white-space: pre-line;
}
</style>
